<template>
  <div>
    <div class="info-container mx-auto py-4">
      <div class="modal fade" id="errorModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="errorModalLabel">
              You are not authorized to execute this action.</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="row mt-4 align-items-center mb-4">
          <div>
            <div class="bg-white p-0 accordion-card rounded shadow">
              <table class="p-2 table scroll-table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Due date</th>
                    <th>Status</th>
                    <th class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="assObj.length>0">
                  <tr v-for="ass, ind in assObj" :key="ind">
                    <td style="font-weight: bold;">{{ ass.title }}</td>
                    <td> {{ ass.aType.type }}</td>
                    <td>{{ assStatus[ass.id].dueDate }}</td>
                    <td>
                      <span v-if="assStatus[ass.id].actionable &&
                      ass.type == 'Homework' && ass.submitted">
                        Submitted
                      </span>
                      <span v-else-if="assStatus[ass.id].actionable
                      && ass.type == 'Homework'">
                        Incomplete
                      </span>
                      <span v-else-if="assStatus[ass.id].actionable
                      && ass.type == 'Quiz'">
                        {{ ass.attemptsAllowed - ass.attemptsUsed }}
                        out of {{ ass.attemptsAllowed }} left
                      </span>
                      <span v-else-if="assStatus[ass.id].actionable
                      && ass.type == 'Exam'">
                        Open
                      </span>
                      <span v-else-if="assStatus[ass.id].status == 'Not open'">
                        <i class="material-icons align-middle md-16 mr-1">
                          schedule
                        </i>
                        <span class="text-small align-middle">{{ ass.openDate }}</span>
                      </span>
                      <span v-else-if="assStatus[ass.id].status == 'Closed' && ass.points">
                        Graded
                      </span>
                      <span v-else>
                        Not graded
                      </span>
                    </td>
                    <td class="text-center">
                      <button :disabled="!assStatus[ass.id].actionable" class="btn btn-light"
                        @click="loadAssessmentTaking(ass.id)">
                        <i class="material-icons align-middle" data-toggle="tooltip"
                          title="Open">
                          open_in_browser
                        </i>
                      </button>
                      <button :disabled="!(ass.points && ass.releaseGrades)" class="btn btn-light"
                      @click="reviewAssessment(ass.id)">
                        <i class="material-icons align-middle" data-toggle="tooltip"
                        title="Review">
                          pageview
                        </i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5" class="text-center">
                      No assignments to display.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import 'vue-datetime/dist/vue-datetime.css';
import {
  required,
} from 'vuelidate/lib/validators';
import 'datatables.net';
import Swal from 'sweetalert2';

export default {
  name: 'Dashboard',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      assObj: {},
      draftObj: {},
      editFocus: {
        title: null,
        type: null,
      },
      assFocus: null,
      courseFocus: null,
      assStatus: {},
      intervalFunction: null,
      releaseDate: null,
      pubTable: null,
      dueDate: null,
      classId: null,
    };
  },
  validations: {
    edit: {
      editTitle: { required },
      editDuration: {
        required,
        minValue(val) {
          return val > 0;
        },
      },
      editAttempts: {
        required,
        minValue(val) {
          return val > 0;
        },
      },
    },
    dueDate: {
      minValue(val) {
        return new Date(val) > new Date(this.releaseDate);
      },
    },
    copy: {
      copyName: { required },
    },
  },
  methods: {
    viewCourse(courseid) {
      this.clearTimer();
      this.courseFocus = courseid;
      this.updateStatus();
      this.intervalFunction = setInterval(this.updateStatus, 10000);
    },
    prettyDate(date) {
      const tempDate = new Date(date);
      // console.log(date);
      const month = this.checkDigit(tempDate.getMonth() + 1);
      const day = this.checkDigit(tempDate.getDate());
      const year = this.checkDigit(tempDate.getFullYear());
      const hour = this.checkDigit(tempDate.getHours());
      const minute = this.checkDigit(tempDate.getMinutes());

      return `${year}/${month}/${day} ${hour}:${minute}`;
    },
    checkDigit(val) {
      if (String(val).length < 2) { return `0${val}`; }
      return val;
    },
    clearTimer() {
      clearInterval(this.intervalFunction);
    },
    updateStatus() {
      /* eslint-disable no-underscore-dangle */
      this.assObj.forEach((ass) => {
        const openDate = new Date(ass.schedUTC).getTime() / 1000;
        const dueDate = openDate + (ass.totTime * 60);
        const now = new Date().getTime() / 1000;

        console.log(ass);
        if (now < openDate) {
          this.assStatus[ass.id] = {
            actionable: false,
            status: 'Not open',
          };
        } else if (now < dueDate) {
          this.assStatus[ass.id] = {
            actionable: true,
          };
        } else {
          this.assStatus[ass.id] = {
            actionable: false,
            status: 'Closed',
          };
        }
        this.assStatus[ass.id].dueDate = this.calcDueDate(ass);
      });
      /* eslint-disable no-underscore-dangle */
      this.$forceUpdate();
    },
    loadAssCreation() {
      // this.$store.commit('setAssignment', { assID: null });
      this.$router.push(`/edittask/${this.classId}`);
    },
    loadAssMonitor(key) {
      this.$store.commit('setAssignment', { assID: key });
      this.$router.push('/monitor');
    },
    loadAssessmentTaking(key) {
      // this.$store.commit('setAssignment', { assID: key });
      this.$router.push(`/submittask/${this.classId}?aID=${key}`);
    },
    deleteAssessment(key) {
      Swal.fire({
        title: 'Are you sure you want to delete this announcement?',
        text: 'This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit('setLoadScreen', { status: true });
          axios.post('/api/assessment/delete', {
            assID: key.id,
            user: this.userData.id,
          })
            .then(() => {
              this.getAss();
              this.$store.commit('setLoadScreen', { status: false });
              Swal.fire(
                'Deleted!',
                'The assessment has been deleted.',
                'success',
              );
            }).catch((err) => {
              this.$store.commit('setLoadScreen', { status: false });
              Swal.fire(
                'Oops!',
                'The assessment could not be deleted.',
                'error',
              );
              console.log(err);
            });
        }
      });
    },
    reviewAssessment(key) {
      this.$router.push(`/reviewtask/${this.classId}?aID=${key}`);
    },
    calcDuration() {
      if (parseInt(this.editFocus.aType.id, 10) === 1) {
        let releaseTemp = new Date(this.releaseDate);
        let dueTemp = new Date(this.dueDate);
        releaseTemp = releaseTemp.getTime();
        dueTemp = dueTemp.getTime();
        // console.log(releaseTemp);
        this.editFocus.totTime = (dueTemp - releaseTemp) / 60000;
      }
    },
    calcDueDate(ass) {
      let tempR = new Date(ass.schedUTC);
      tempR = tempR.getTime();
      let dueDate = tempR + ass.totTime * 60000;
      dueDate = this.prettyDate(new Date(dueDate));
      return dueDate;
    },
    getAss() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/assessment/course/${this.classId}`, {
        params: {
          user: this.userData.id,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          this.assObj = response.data.assignmentList;
          this.draftObj = response.data.draftList;
          // this.subList = response.data.submissionList;
          this.updateStatus();
          setTimeout(() => {
            this.pubTable = $('#assTable').DataTable({
              fixedHeader: true,
              autoWidth: false,
              info: false,
              searching: false,
              paging: false,
              retrieve: true,
              ordering: true,
              columnDefs: [
                { orderable: false, targets: 4 },
              ],
            });
          }, 10);
          this.$store.commit('setLoadScreen', { status: false });
          // this.viewCourse(Object.keys(this.assObj)[0]);
        // let calendarEl = document.getElementById("calendar");
        // var calendar = new FullCalendar.Calendar(calendarEl, {
        //   initialView: 'dayGridMonth',
        //   headerToolbar: {
        //     start: 'prev,next today',
        //     center: 'title',
        //     end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        //   }
        // });
        // calendar.render();
        }).catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
  },
  mounted() {
    this.classId = this.$route.params.cid;
    this.getAss();
  },
  beforeRouteLeave(to, from, next) {
    this.clearTimer();
    next();
  },
};
</script>

<style scoped>
.accordion-title button{
  color: #fff;
  font-weight: bold;
  background: linear-gradient(90deg, rgba(54,69,79,1) 0%,
    rgba(61,89,152,1) 47%, rgba(69,206,235,1) 100%);
}
.accordion-card{
  max-height: 23em !important;
  overflow-y: scroll;
}
.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}
.accordion-heading>a{
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  padding: 0.5em;
}
.scroll-table{
  border-collapse: collapse;
  width: 100%;
  /*display: block;*/
}
.scroll-table tbody{
  font-size: 14px;
}
.scroll-table thead th{
  position: sticky;
  top: 0;
  z-index: 1;
  border:  0;

}

.scroll-table th {
  background-color: rgba(209,213,222);
  color: #0a0a0a;

}

.scroll-table td {
  vertical-align: middle;
}

.dropdown{
  position: inherit;
}
</style>
