<template>
  <div>
    <InstructorTasks v-if="userData.roleId == 2"></InstructorTasks>
    <StudentTasks v-else-if="userData.roleId == 1"></StudentTasks>
  </div>
</template>
<script>

import InstructorTasks from './InstructorTasks.vue';
import StudentTasks from './StudentTasks.vue';

export default {
  name: 'Home',
  components: {
    InstructorTasks,
    StudentTasks,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      // mode: null,
      // code: null,
    };
  },
  mounted() {
  },
};
</script>
<style scoped>
</style>
